import React, {useState, useEffect} from "react";
import styles from "./Modal.module.css";

import AccountUpdatePage from "../components/AccountUpdatePage.js";
import CreateClerk from "../pages/CreateClerk"
import CreateCafe from "../pages/CreateCafe"
import CreateTenant from "../pages/CreateTenant"
import TablesPage from "./TablesPage.js";
import PaymentOptions from "./PaymentOptions.js";
import TableMaps from "../components/TableMaps";
import Transaction from "../pages/Transaction";
import Transaction_pending from "../pages/Transaction_pending";
import Transaction_confirmed from "../pages/Transaction_confirmed";
import Transaction_success from "../pages/Transaction_success";
import Transaction_end from "../pages/Transaction_end";
import Transaction_failed from "../pages/Transaction_failed";
import Payment_claimed from "../pages/Payment_claimed";
import MaterialList from "../pages/MaterialList.js";
import MaterialMutationsPage from "../pages/MaterialMutationsPage.js";
import Reports from "../pages/Reports.js";
import NotificationRequest from "../pages/NotificationRequest.js";
import NotificationBlocked from "../pages/NotificationBlocked.js";
import WelcomePageEditor from "../pages/WelcomePageEditor.js";
import GuidePage from "../pages/GuidePage";
import Join from "../pages/Join";
import Login from "../pages/Login";
import ResetPassword from "../pages/ResetPassword";
import { getImageUrl } from "../helpers/itemHelper.js";

const Modal = ({ user, shop, isOpen, onClose, modalContent, setModal, handleMoveToTransaction,welcomePageConfig }) => {
  
  const [shopImg, setShopImg] = useState('');

  useEffect(() => {
    if (welcomePageConfig) {
      const parsedConfig = JSON.parse(welcomePageConfig);
      setShopImg( getImageUrl(parsedConfig.image) || "")
    }
  }, [welcomePageConfig]);
  
  if (!isOpen) return null;

  // Function to handle clicks on the overlay
  const handleOverlayClick = (event) => {
    // Close the modal only if the overlay is clicked
    onClose();
  };

  // Function to handle clicks on the modal content
  const handleContentClick = (event) => {
    // Prevent click event from propagating to the overlay
    event.stopPropagation();
  };
  return (
    <div onClick={handleOverlayClick} className={styles.modalOverlay}>
      <div className={styles.modalContent} onClick={handleContentClick}>
        
      {modalContent === "edit_account" && <AccountUpdatePage user={user} />}
        {modalContent === "join" && <Join />}
        {modalContent === "reset-password" && <ResetPassword />}
        {modalContent === "req_notification" && <NotificationRequest setModal={setModal} />}
        {modalContent === "blocked_notification" && <NotificationBlocked />}
        {modalContent === "create_clerk" && <CreateClerk shopId={shop.cafeId} />}
        {modalContent === "create_kedai" && <CreateCafe shopId={shop.cafeId} />}
        {modalContent === "create_tenant" && <CreateTenant shopId={shop.cafeId} />}
        {modalContent === "edit_tables" && <TablesPage shop={shop} />}
        {modalContent === "new_transaction" && (
          <Transaction propsShopId={shop.cafeId} handleMoveToTransaction={handleMoveToTransaction} shopImg={shopImg} />
        )}
        {modalContent === "transaction_canceled" && (
          <Transaction propsShopId={shop.cafeId} />
        )}
        {modalContent === "transaction_pending" && <Transaction_pending />}
        {modalContent === "transaction_confirmed" && (
          <Transaction_confirmed paymentUrl={shop.qrPayment} />
        )}
        {modalContent === "payment_claimed" && (
          <Payment_claimed paymentUrl={shop.qrPayment} />
        )}
        
        {modalContent === "create_item" && (
          <GuidePage guideType={'create_item'} />
        )}
        {modalContent === "transaction_success" && <Transaction_success setModal={setModal}/>}
        {modalContent === "transaction_end" && <Transaction_end />}
        {modalContent === "transaction_failed" && <Transaction_failed />}
        {modalContent === "payment_option" && (
          <PaymentOptions shop={shop} shopId={shop.cafeId} />
        )}
        {modalContent === "add_material" && (
          <MaterialList handleClose={handleOverlayClick} cafeId={shop.cafeId} />
        )}
        {modalContent === "update_stock" && (
          <MaterialMutationsPage  handleClose={handleOverlayClick} cafeId={shop.cafeId} />
        )}
        {modalContent === "welcome_config" && <WelcomePageEditor cafeId={shop.cafeId} welcomePageConfig={shop.welcomePageConfig} />}
        {modalContent === "reports" && <Reports handleClose={handleOverlayClick} cafeId={shop.cafeId} />}
      </div>
    </div>
  );
};

export default Modal;
