import React, { useRef, useEffect, useState } from "react";
import styles from "./Invoice.module.css";
import { useParams } from "react-router-dom"; // Changed from useSearchParams to useLocation
import { ThreeDots, ColorRing } from "react-loader-spinner";

import ItemLister from "../components/ItemLister";
import { getCartDetails } from "../helpers/itemHelper";
import {
  handlePaymentFromClerk,
  handlePaymentFromGuestSide,
  handlePaymentFromGuestDevice,
} from "../helpers/transactionHelpers";

import { getItemsByCafeId } from "../helpers/cartHelpers.js";

export default function Invoice({ table, sendParam, deviceType, socket }) {
  const { shopId, tableCode } = useParams();
  sendParam({ shopId, tableCode });

  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false); // State for payment button loading animation

  const textareaRef = useRef(null);
  const [orderType, setOrderType] = useState("pickup");
  const [orderMethod, setOrderMethod] = useState("cash");
  const [tableNumber, setTableNumber] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const items = await getCartDetails(shopId);
        console.log(items);

        // Filter out unavailable items
        const filteredItems = items
          .map((itemType) => ({
            ...itemType,
            itemList: itemType.itemList.filter((item) => item.availability),
          }))
          .filter((itemType) => itemType.itemList.length > 0); // Remove empty itemTypes

        setCartItems(filteredItems);
        console.log(filteredItems)

        // Update local storage by removing unavailable items
        const updatedLocalStorage =
          JSON.parse(localStorage.getItem("cart")) || [];
        const newLocalStorage = updatedLocalStorage.map((cafe) => {
          if (cafe.cafeId === shopId) {
            return {
              ...cafe,
              items: cafe.items.filter((item) =>
                filteredItems.some((filtered) =>
                  filtered.itemList.some(
                    (i) => i.itemId === item.itemId && i.availability
                  )
                )
              ),
            };
          }
          return cafe;
        });
        localStorage.setItem("cart", JSON.stringify(newLocalStorage));

        window.dispatchEvent(new Event("localStorageUpdated"));
        // Calculate total price based on filtered cart items
        const totalPrice = filteredItems.reduce((total, itemType) => {
          return (
            total +
            itemType.itemList.reduce((subtotal, item) => {
              return subtotal + item.qty * item.price;
            }, 0)
          );
        }, 0);
        setTotalPrice(totalPrice);
      } catch (error) {
        console.error("Error fetching cart items:", error);
        // Handle error if needed
      }
    };

    fetchCartItems();
  }, [shopId]);

  const handlePay = async (isCash) => {
    setIsPaymentLoading(true);
    console.log("tipe" + deviceType);
    if (deviceType == "clerk") {
      const pay = await handlePaymentFromClerk(
        shopId,
        email,
        isCash ? "cash" : "cashless",
        orderType,
        tableNumber
      );
    } else if (deviceType == "guestSide") {
      const pay = await handlePaymentFromGuestSide(
        shopId,
        email,
        isCash ? "cash" : "cashless",
        orderType,
        tableNumber
      );
    } else if (deviceType == "guestDevice") {
      const socketId = socket.id;
      const pay = await handlePaymentFromGuestDevice(
        shopId,
        isCash ? "cash" : "cashless",
        orderType,
        table.tableNo || tableNumber,
        textareaRef.current.value,
        socketId
      );
    }

    console.log("transaction from " + deviceType + "success");
    setIsPaymentLoading(false);
  };

  useEffect(() => {
    const textarea = textareaRef.current;

    if (textarea) {
      const handleResize = () => {
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      };

      handleResize(); // Initial resize

      textarea.addEventListener("input", handleResize);
      return () => textarea.removeEventListener("input", handleResize);
    }
  }, [textareaRef.current]);

  useEffect(() => {
    if (table?.tableId != undefined) setOrderType("serve");
    console.log(table);
  }, [table]);

  useEffect(() => {
    console.log(localStorage.getItem('cart'))
    console.log(cartItems)

    if(localStorage.getItem('cart') == "[]") return;

    // Parse the local storage cart
    const localStorageCart = JSON.parse(localStorage.getItem('cart'));
    console.log(localStorageCart)
    // Create a set of itemIds from the local storage cart for quick lookup
    const localStorageItemIds = new Set(localStorageCart[0].items.map(item => item.itemId));

    // Filter out items from cartItems that do not exist in the local storage cart
    const updatedCartItems = cartItems.map(itemType => ({
        ...itemType,
        itemList: itemType.itemList.filter(item => localStorageItemIds.has(item.itemId))
    }));

    setCartItems(updatedCartItems);


    const totalPrice = updatedCartItems.reduce((total, itemType) => {
      return (
        total +
        itemType.itemList.reduce((subtotal, item) => {
          return subtotal + item.qty * item.price;
        }, 0)
      );
    }, 0);
    setTotalPrice(totalPrice);
  }, [localStorage.getItem('cart')]);


  const handleOrderTypeChange = (event) => {
    setOrderType(event.target.value);
  };

  const handleOrderMethodChange = (event) => {
    setOrderMethod(event.target.value);
  };

  const handleTableNumberChange = (event) => {
    setTableNumber(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  return (
    <div className={styles.Invoice} style={{height: (getItemsByCafeId(shopId).length > 0 ? '': 'calc(100vh - 75px)'), minHeight: (getItemsByCafeId(shopId).length > 0 ? 'calc(100vh - 210px)': '') }}>
      
      <div style={{ marginTop: "50px" }}></div>
      <h2 className={styles["Invoice-title"]}>Keranjang</h2>
      {getItemsByCafeId(shopId) < 1 ?
      <div style={{height: '75vh', display:'flex', justifyContent: 'center', flexDirection: 'column', alignContent: 'center', alignItems: 'center'}}>
        <div style={{width: '50%'}}>
      <svg
      viewBox="0 0 32 32"
      style={{ fill: "#8F8787" }}
    >
      <path d="M9.79175 24.75C8.09591 24.75 6.72383 26.1375 6.72383 27.8333C6.72383 29.5292 8.09591 30.9167 9.79175 30.9167C11.4876 30.9167 12.8751 29.5292 12.8751 27.8333C12.8751 26.1375 11.4876 24.75 9.79175 24.75ZM0.541748 0.0833435V3.16668H3.62508L9.17508 14.8679L7.09383 18.645C6.84717 19.0767 6.70842 19.5854 6.70842 20.125C6.70842 21.8208 8.09591 23.2083 9.79175 23.2083H28.2917V20.125H10.4392C10.2234 20.125 10.0538 19.9554 10.0538 19.7396L10.1001 19.5546L11.4876 17.0417H22.973C24.1292 17.0417 25.1467 16.4096 25.6709 15.4538L31.1901 5.44834C31.3134 5.23251 31.3751 4.97043 31.3751 4.70834C31.3751 3.86043 30.6813 3.16668 29.8334 3.16668H7.03217L5.583 0.0833435H0.541748ZM25.2084 24.75C23.5126 24.75 22.1405 26.1375 22.1405 27.8333C22.1405 29.5292 23.5126 30.9167 25.2084 30.9167C26.9042 30.9167 28.2917 29.5292 28.2917 27.8333C28.2917 26.1375 26.9042 24.75 25.2084 24.75Z" />
    </svg>
    </div>
    <h1 style={{fontSize: '120%', color :'#8F8787' }}>Tidak ada item di keranjang</h1>
    </div>
      :
      <>
      <div className={styles.RoundedRectangle}>
        {cartItems.map((itemType) => (
          <ItemLister
            shopId={shopId}
            forInvoice={true}
            key={itemType.id}
            typeName={itemType.typeName}
            itemList={itemType.itemList}
          />
        ))}

{table.tableNo != null && (
        <div className={styles.OrderTypeContainer}>
            <span htmlFor="orderType">Diantar ke {table.tableNo}</span>
          {/* <select
            id="orderType"
            value={orderType}
            onChange={handleOrderTypeChange}
          >
            {table != null && (
              <option value="serve">Serve to table {table.tableNo}</option>
            )}
            <option value="pickup">Pickup</option>
            {table == null && <option value="serve">Serve</option>}
          </select> */}
        </div>
          )}
        {orderType === "serve" && table.length < 1 && (
          <div className={styles.OrderTypeContainer}>
            <span htmlFor="orderType">Serve to:</span>
            <input
              type="text"
              placeholder="Table Number"
              value={tableNumber}
              onChange={handleTableNumberChange}
              className={styles.TableNumberInput}
            />
          </div>
        )}

        <div className={styles.NoteContainer}>
          <span>Catatan :</span>
          <span></span>
        </div>

        <div className={styles.NoteContainer}>
          <textarea
            ref={textareaRef}
            className={styles.NoteInput}
            placeholder="Tambahkan catatan..."
          />
        </div>
      </div>
      <div className={styles.PaymentOption}>
        <div className={styles.TotalContainer}>
          <span>Pembayaran</span>
          <span>
            <select
            style={{borderRadius: '6px', fontSize: '20px'}}
            id="orderMethod"
            value={orderMethod}
            onChange={handleOrderMethodChange}
          >
            <option value="cash">&nbsp;Tunai</option>
            <option value="cashless">&nbsp;Non Tunai&nbsp;</option>
          </select>
          </span>
        </div>
        <div style={{display: 'flex', paddingLeft: '25px', paddingRight: '25px'}}>
          <button className={styles.PayButton} onClick={() => handlePay(orderMethod == 'cash' ? true : false)}>
            {isPaymentLoading ? (
              <ColorRing height="50" width="50" color="white" />
            ) : (
              <div>
                <span>Pesan</span>
                
                <span>Rp{totalPrice}</span>
              </div>
            )}
          </button>
        </div>
      </div>
      <div className={styles.PaymentOptionMargin}></div>
      </>
}
    </div>
  );
}
